<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0"></ol>
          </div>
          <h4 class="page-title">Training Plan Detail</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>
            <div class="row gutter-css" v-if="!loading">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-6 custom-border">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody v-if="!loading">
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module Title</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Type</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.employee_type.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module Group</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.group.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">From Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.from_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">To Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.to_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Enrolled Expired Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.expired_date
                                }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                    <div class="col-xl-6">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody v-if="!loading">
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Training Hours</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.duration
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Trainer</span>
                              </th>
                              <td>
                                <!-- style="display: block" -->
                                <span
                                  class="ms-1"
                                  v-for="(
                                    trainer, index
                                  ) in monthly_plan.trainers"
                                  :key="index"
                                  >{{ trainer.name }}
                                  <span
                                    v-if="
                                      index + 1 < monthly_plan.trainers.length
                                    "
                                    >,
                                  </span>
                                </span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Location</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.location
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th class="status-top">
                                <span class="ms-1">Status</span>
                              </th>
                              <td>
                                <!-- height: 165px; -->

                                <!-- <div
                                  style="width: 165px; padding-left: 6px"
                                  v-if="
                                    monthly_plan.employee_type.name == 'Normal'
                                  "
                                >
                                  <v-select
                                    v-if="
                                      monthly_plan.is_plan_confirmed == 0 ||
                                      monthly_plan.is_plan_confirmed == 1 ||
                                      monthly_plan.expired_date >= current_date
                                    "
                                    id="vue-selectCss"
                                    v-model="plan_confirm_status"
                                    label="name"
                                    :options="plan_status"
                                    :reduce="(name) => name.id"
                                    :clearable="false"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    :title="
                                      monthly_plan.expired_date >= current_date
                                        ? 'You can not change status if plan is during registration period!'
                                        : 'You can not change status if plan is confirmed or rejected!'
                                    "
                                  >
                                  </v-select>
                                  <v-select
                                    v-else-if="
                                      monthly_plan.is_plan_confirmed == 4 ||
                                      monthly_plan.expired_date >= current_date
                                    "
                                    id="vue-selectCss"
                                    v-model="plan_confirm_status"
                                    label="name"
                                    :options="plan_status"
                                    :reduce="(name) => name.id"
                                    :clearable="false"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    title="You can not change status if plan is cancelled!
                                    "
                                  >
                                  </v-select>
                                  <div v-else>
                                    <v-select
                                      v-if="
                                        current_date > change_from_date_format
                                      "
                                      id="vue-selectCss"
                                      v-model="plan_confirm_status"
                                      label="name"
                                      :options="plan_status"
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                      :disabled="
                                        monthly_plan.from_date <= current_date
                                          ? true
                                          : false
                                      "
                                      :selectable="
                                        (option) =>
                                          !option.name.includes('Cancelled')
                                      "
                                      data-bs-toggle="tooltip"
                                      :title="
                                        monthly_plan.from_date <= current_date
                                          ? 'You can not change status after plan is overdue from date!'
                                          : ''
                                      "
                                    >
                                    </v-select>
                                    <v-select
                                      v-else
                                      id="vue-selectCss"
                                      v-model="plan_confirm_status"
                                      label="name"
                                      :options="plan_status"
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                      :disabled="
                                        monthly_plan.from_date <= current_date
                                          ? true
                                          : false
                                      "
                                      data-bs-toggle="tooltip"
                                      :title="
                                        monthly_plan.from_date <= current_date
                                          ? 'You can not change status after plan is overdue from date!'
                                          : ''
                                      "
                                    >
                                    </v-select>
                                  </div>
                                </div> -->

                                <!-- Normal -->
                                <div
                                  style="width: 165px; padding-left: 6px"
                                  v-if="
                                    monthly_plan.employee_type.name == 'Normal'
                                  "
                                >
                                  <!-- monthly_plan.expired_date >= current_date -->
                                  <v-select
                                    v-if="
                                      monthly_plan.is_plan_confirmed == 0 ||
                                      monthly_plan.is_plan_confirmed == 1 ||
                                      monthly_plan.is_plan_confirmed == 4
                                    "
                                    id="vue-selectCss"
                                    v-model="plan_confirm_status"
                                    label="name"
                                    :options="plan_status"
                                    :reduce="(name) => name.id"
                                    :clearable="false"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    :title="
                                      monthly_plan.is_plan_confirmed == 4
                                        ? 'You can not change status if plan is cancelled!'
                                        : 'You can not change status if plan is confirmed or rejected!'
                                    "
                                  >
                                  </v-select>
                                  <v-select
                                    v-else-if="
                                      monthly_plan.from_date <= current_date
                                    "
                                    id="vue-selectCss"
                                    v-model="plan_confirm_status"
                                    label="name"
                                    :options="plan_status"
                                    :reduce="(name) => name.id"
                                    :clearable="false"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    title="'You can not change status after plan is overdue from date!'
                                      "
                                  >
                                  </v-select>
                                  <div
                                    v-else-if="
                                      current_date > change_from_date_format
                                    "
                                  >
                                  {{ change_from_date_format }}
                                    <v-select
                                      v-if="
                                        monthly_plan.expired_date >=
                                        current_date
                                      "
                                      id="vue-selectCss"
                                      v-model="plan_confirm_status"
                                      label="name"
                                      :options="plan_status"
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                      disabled
                                      data-bs-toggle="tooltip"
                                      title="'You can not change status if plan is during registration period!'
                                      "
                                    >
                                    </v-select>
                                    <v-select
                                      v-else
                                      id="vue-selectCss"
                                      v-model="plan_confirm_status"
                                      label="name"
                                      :options="plan_status"
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                      :selectable="
                                        (option) =>
                                          !option.name.includes('Cancelled')
                                      "
                                    >
                                    </v-select>
                                  </div>
                                  <div v-else>
                                    <v-select
                                      id="vue-selectCss"
                                      v-model="plan_confirm_status"
                                      label="name"
                                      :options="plan_status"
                                      :reduce="(name) => name.id"
                                      :clearable="false"
                                    >
                                    </v-select>
                                  </div>
                                </div>

                                <!-- Program -->
                                <div
                                  style="width: 165px; padding-left: 6px"
                                  v-else
                                >
                                  <!-- current_date > change_from_date_format -->
                                  <v-select
                                    id="vue-selectCss"
                                    v-model="plan_confirm_status"
                                    label="name"
                                    :options="plan_status"
                                    :reduce="(name) => name.id"
                                    :clearable="false"
                                    :selectable="
                                      (option) =>
                                        !option.name.includes('Rejected')
                                    "
                                  >
                                  </v-select>
                                </div>
                              </td>
                            </tr>
                            <tr
                              class="custom-tr"
                              v-if="
                                plan_confirm_status == 0 ||
                                plan_confirm_status == 4
                              "
                            >
                              <th>
                                <span class="ms-1">Remark</span>
                              </th>
                              <!-- <td>
                                <textarea
                                  v-if="
                                    monthly_plan.is_plan_confirmed == 0 ||
                                    monthly_plan.is_plan_confirmed == 1 ||
                                    monthly_plan.expired_date >= current_date
                                  "
                                  style="width: 159px; margin-left: 6px"
                                  v-model="monthly_plan.plan_remark"
                                  class="form-control"
                                  placeholder="Please add remark"
                                  name="plan_remark"
                                  id="plan_remark"
                                  disabled
                                  data-bs-toggle="tooltip"
                                  :title="
                                    monthly_plan.expired_date >= current_date
                                      ? 'You can not change remark if plan is during registration period!'
                                      : 'You can not change remark if plan is confirmed or rejected!'
                                  "
                                />
                                <textarea
                                  v-else-if="
                                    monthly_plan.is_plan_confirmed == 4 ||
                                    monthly_plan.expired_date >= current_date
                                  "
                                  style="width: 159px; margin-left: 6px"
                                  v-model="monthly_plan.plan_remark"
                                  class="form-control"
                                  placeholder="Please add remark"
                                  name="plan_remark"
                                  id="plan_remark"
                                  disabled
                                  data-bs-toggle="tooltip"
                                  title="You can not change remark if plan is cancelled!
                                  "
                                />
                                <textarea
                                  v-else
                                  style="width: 159px; margin-left: 6px"
                                  v-model="monthly_plan.plan_remark"
                                  class="form-control"
                                  placeholder="Please add remark"
                                  name="plan_remark"
                                  id="plan_remark"
                                  :disabled="
                                    monthly_plan.from_date <= current_date
                                      ? true
                                      : false
                                  "
                                  data-bs-toggle="tooltip"
                                  :title="
                                    monthly_plan.from_date <= current_date
                                      ? 'You can not change remark after plan is overdue from date!'
                                      : ''
                                  "
                                  :class="{
                                    'p-invalid':
                                      v$.monthly_plan.plan_remark.$error ||
                                      errorFor('plan_remark'),
                                  }"
                                />
                                <v-errors
                                  id="custom-CSS"
                                  :serverErrors="errorFor('plan_remark')"
                                  :vuelidateErrors="{
                                    errors: v$.monthly_plan.plan_remark.$errors,
                                    value: 'Remark',
                                  }"
                                ></v-errors>
                              </td> -->
                              <td>
                                <textarea
                                  v-if="
                                    monthly_plan.is_plan_confirmed == 0 ||
                                    monthly_plan.is_plan_confirmed == 1 ||
                                    monthly_plan.is_plan_confirmed == 4
                                  "
                                  style="width: 159px; margin-left: 6px"
                                  v-model="monthly_plan.remark"
                                  class="form-control"
                                  placeholder="Please add remark"
                                  name="remark"
                                  id="remark"
                                  disabled
                                  data-bs-toggle="tooltip"
                                  :title="
                                    monthly_plan.is_plan_confirmed == 4
                                      ? 'You can not change remark if plan is cancelled!'
                                      : 'You can not change remark if plan is confirmed or rejected!'
                                  "
                                />
                                <textarea
                                  v-else-if="
                                    monthly_plan.from_date <= current_date
                                  "
                                  style="width: 159px; margin-left: 6px"
                                  v-model="monthly_plan.remark"
                                  class="form-control"
                                  placeholder="Please add remark"
                                  name="remark"
                                  id="remark"
                                  disabled
                                  data-bs-toggle="tooltip"
                                  title="You can not change remark after plan is overdue from date!
                                    "
                                />
                                <div
                                  v-else-if="
                                    current_date > change_from_date_format
                                  "
                                >
                                  <textarea
                                    v-if="
                                      monthly_plan.expired_date >= current_date
                                    "
                                    style="width: 159px; margin-left: 6px"
                                    v-model="monthly_plan.remark"
                                    class="form-control"
                                    placeholder="Please add remark"
                                    name="remark"
                                    id="remark"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    title="'You can not change remark if plan is during registration period!'"
                                  />
                                  <textarea
                                    v-else
                                    style="width: 159px; margin-left: 6px"
                                    v-model="monthly_plan.remark"
                                    class="form-control"
                                    placeholder="Please add remark"
                                    name="remark"
                                    id="remark"
                                    :class="{
                                      'p-invalid':
                                        v$.monthly_plan.remark.$error ||
                                        errorFor('remark'),
                                    }"
                                  />
                                  <v-errors
                                    id="custom-CSS"
                                    :serverErrors="errorFor('remark')"
                                    :vuelidateErrors="{
                                      errors: v$.monthly_plan.remark.$errors,
                                      value: 'Remark',
                                    }"
                                  ></v-errors>
                                </div>
                                <div v-else>
                                  <textarea
                                    style="width: 159px; margin-left: 6px"
                                    v-model="monthly_plan.remark"
                                    class="form-control"
                                    placeholder="Please add remark"
                                    name="remark"
                                    id="remark"
                                  />
                                  <v-errors
                                    id="custom-CSS"
                                    :serverErrors="errorFor('remark')"
                                    :vuelidateErrors="{
                                      errors: v$.monthly_plan.remark.$errors,
                                      value: 'Remark',
                                    }"
                                  ></v-errors>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-centered mb-0"
                id="trainingEnroll_confirm_datatable"
              >
                <thead class="table-light">
                  <tr>
                    <th>No</th>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Position Level</th>
                    <th>Employee Status</th>
                    <th>Department</th>
                    <th>BU/BR/DIV</th>
                    <th>Approved Status</th>
                    <th>Status</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(item, index) in filterTrainingPlan" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.emp_id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.position_name }}</td>
                    <td>{{ capitalizeFirstLetter(item.employee_status)  }}</td>
                    <td>{{ item.department_name }}</td>
                    <td>
                      {{ item.business_unit_name }}
                    </td>
                    <td>{{ item.approved_status }}</td>
                    <td
                      :class="{ customWidth: filterTrainingPlan.length == 1 }"
                    >
                      <!-- <div style="width: 130px">
                        <v-select
                          v-if="
                            monthly_plan.expired_date >= current_date ||
                            monthly_plan.from_date <= current_date
                          "
                          id="lastChildCSS"
                          v-model="item.is_approved_by_trainer"
                          label="name"
                          :options="enroll_confirmed_status"
                          :reduce="(name) => name.id"
                          :clearable="false"
                          disabled
                          data-bs-toggle="tooltip"
                          :title="
                            monthly_plan.expired_date >= current_date
                              ? 'You can not change status if plan is during registration period!'
                              : 'You can not change status after plan is overdue from date!'
                          "
                        >
                        </v-select>
                        <v-select
                          v-else-if="monthly_plan.is_plan_confirmed == 4"
                          id="lastChildCSS"
                          v-model="item.is_approved_by_trainer"
                          label="name"
                          :options="enroll_confirmed_status"
                          :reduce="(name) => name.id"
                          :clearable="false"
                          disabled
                          data-bs-toggle="tooltip"
                          title="You can not change status if plan is cancelled!"
                        >
                        </v-select>
                        <v-select
                          v-else
                          id="lastChildCSS"
                          v-model="item.is_approved_by_trainer"
                          label="name"
                          :options="enroll_confirmed_status"
                          :reduce="(name) => name.id"
                          :clearable="false"
                          :disabled="
                            monthly_plan.is_plan_confirmed == 1 ||
                            monthly_plan.is_plan_confirmed == 0
                              ? true
                              : false
                          "
                          data-bs-toggle="tooltip"
                          :title="
                            monthly_plan.is_plan_confirmed == 1 ||
                            monthly_plan.is_plan_confirmed == 0
                              ? 'You can not change status after plan is confirmed or postponed!'
                              : ''
                          "
                        >
                        </v-select>
                      </div> -->

                      <!-- Normal -->

                      <div v-if="monthly_plan.employee_type.name == 'Normal'">
                        <div style="width: 130px">
                          <v-select
                            v-if="
                              monthly_plan.is_plan_confirmed == 0 ||
                              monthly_plan.is_plan_confirmed == 1 ||
                              monthly_plan.is_plan_confirmed == 4
                            "
                            id="lastChildCSS"
                            v-model="item.is_approved_by_trainer"
                            label="name"
                            :options="enroll_confirmed_status"
                            :reduce="(name) => name.id"
                            :clearable="false"
                            disabled
                            data-bs-toggle="tooltip"
                            :title="
                              monthly_plan.is_plan_confirmed == 4
                                ? 'You can not change status if plan is cancelled!'
                                : 'You can not change status if plan is confirmed or rejected!'
                            "
                          >
                          </v-select>
                          <v-select
                            v-else-if="monthly_plan.from_date <= current_date"
                            id="lastChildCSS"
                            v-model="item.is_approved_by_trainer"
                            label="name"
                            :options="enroll_confirmed_status"
                            :reduce="(name) => name.id"
                            :clearable="false"
                            disabled
                            data-bs-toggle="tooltip"
                            title="You can not change status after plan is overdue from date!"
                          >
                          </v-select>
                          <div
                            v-else-if="current_date > change_from_date_format"
                          >
                            <v-select
                              v-if="monthly_plan.expired_date >= current_date"
                              id="lastChildCSS"
                              v-model="item.is_approved_by_trainer"
                              label="name"
                              :options="enroll_confirmed_status"
                              :reduce="(name) => name.id"
                              :clearable="false"
                              disabled
                              data-bs-toggle="tooltip"
                              title="You can not change status if plan is during registration period!"
                            >
                            </v-select>
                            <v-select
                              v-else
                              id="lastChildCSS"
                              v-model="item.is_approved_by_trainer"
                              label="name"
                              :options="enroll_confirmed_status"
                              :reduce="(name) => name.id"
                              :clearable="false"
                            >
                            </v-select>
                          </div>
                          <div v-else>
                            <v-select
                              id="lastChildCSS"
                              v-model="item.is_approved_by_trainer"
                              label="name"
                              :options="enroll_confirmed_status"
                              :reduce="(name) => name.id"
                              :clearable="false"
                            >
                            </v-select>
                          </div>
                        </div>
                      </div>

                      <!-- Program -->

                      <div v-else style="width: 130px">
                        <v-select
                          id="lastChildCSS"
                          v-model="item.is_approved_by_trainer"
                          label="name"
                          :options="enroll_confirmed_status"
                          :reduce="(name) => name.id"
                          :clearable="false"
                        >
                        </v-select>
                      </div>
                    </td>

                    <td style="min-width: 130px">
                      <!-- <div v-if="item.is_approved_by_trainer == 0">
                        <textarea
                          v-if="
                            monthly_plan.expired_date >= current_date ||
                            monthly_plan.from_date <= current_date
                          "
                          v-model="item.remark"
                          type="text"
                          name="enroll_remark"
                          class="form-control"
                          placeholder="Add Remark"
                          disabled
                          data-bs-toggle="tooltip"
                          :title="
                            monthly_plan.expired_date >= current_date
                              ? 'You can not change remark if plan is during registration period!'
                              : 'You can not change remark after plan is overdue from date!'
                          "
                        />
                        <textarea
                          v-else-if="monthly_plan.is_plan_confirmed == 4"
                          v-model="item.remark"
                          type="text"
                          name="enroll_remark"
                          class="form-control"
                          placeholder="Add Remark"
                          disabled
                          data-bs-toggle="tooltip"
                          title="You can not change remark if plan is cancelled!"
                        />
                        <textarea
                          v-else
                          v-model="item.remark"
                          type="text"
                          name="enroll_remark"
                          class="form-control"
                          placeholder="Add Remark"
                          :disabled="
                            monthly_plan.is_plan_confirmed == 1 ||
                            monthly_plan.is_plan_confirmed == 0
                              ? true
                              : false
                          "
                          data-bs-toggle="tooltip"
                          :title="
                            monthly_plan.is_plan_confirmed == 1 ||
                            monthly_plan.is_plan_confirmed == 0
                              ? 'You can not change remark after plan is confirmed or postponed!'
                              : ''
                          "
                        />
                      </div> -->
                      <div v-if="item.is_approved_by_trainer == 0">
                        <!-- Normal -->

                        <!-- <div v-if="monthly_plan.employee_type.name == 'Normal'">
                          <textarea
                            v-if="
                              monthly_plan.is_plan_confirmed == 0 ||
                              monthly_plan.is_plan_confirmed == 1 ||
                              monthly_plan.is_plan_confirmed == 4
                            "
                            v-model="item.remark"
                            type="text"
                            name="enroll_remark"
                            class="form-control"
                            placeholder="Add Remark"
                            disabled
                            data-bs-toggle="tooltip"
                            :title="
                              monthly_plan.is_plan_confirmed == 4
                                ? 'You can not change remark if plan is cancelled!'
                                : 'You can not change remark if plan is confirmed or rejected!'
                            "
                          />
                          <textarea
                            v-else-if="monthly_plan.from_date <= current_date"
                            v-model="item.remark"
                            type="text"
                            name="enroll_remark"
                            class="form-control"
                            placeholder="Add Remark"
                            disabled
                            data-bs-toggle="tooltip"
                            title="You can not change remark after plan is overdue from date!"
                          />
                          <div
                            v-else-if="current_date > change_from_date_format"
                          >
                            <textarea
                              v-if="monthly_plan.expired_date >= current_date"
                              v-model="item.remark"
                              type="text"
                              name="enroll_remark"
                              class="form-control"
                              placeholder="Add Remark"
                              disabled
                              data-bs-toggle="tooltip"
                              title="You can not change remark if plan is during registration period!"
                            />
                            <textarea
                              v-else
                              v-model="item.remark"
                              type="text"
                              name="enroll_remark"
                              class="form-control"
                              placeholder="Add Remark"
                            />
                          </div>
                          <div v-else>
                            <textarea
                              v-model="item.remark"
                              type="text"
                              name="enroll_remark"
                              class="form-control"
                              placeholder="Add Remark"
                            />
                          </div>
                        </div> -->

                        <!-- Program -->

                        <!-- <div v-else>
                          <textarea
                            v-model="item.remark"
                            type="text"
                            name="enroll_remark"
                            class="form-control"
                            placeholder="Add Remark"
                          />
                        </div> -->

                        <div v-if="monthly_plan.employee_type.name == 'Normal'">

                          <v-select
                           id="vue-selectCss"
                            v-if="
                              monthly_plan.is_plan_confirmed == 0 ||
                              monthly_plan.is_plan_confirmed == 1 ||
                              monthly_plan.is_plan_confirmed == 4
                            "
                            :options="postponed_status"
                            :clearable="false"
                            v-model="item.remark"                            
                            disabled
                            data-bs-toggle="tooltip"
                            :title="
                              monthly_plan.is_plan_confirmed == 4
                                ? 'You can not change remark if plan is cancelled!'
                                : 'You can not change remark if plan is confirmed or rejected!'
                            "
                            >
                          </v-select>

                          <v-select
                          id="vue-selectCss"
                            v-else-if="monthly_plan.from_date <= current_date"
                            v-model="item.remark"
                            :options="postponed_status"
                            disabled
                            :clearable="false"
                            data-bs-toggle="tooltip"
                            title="You can not change remark after plan is overdue from date!"
                          >

                          </v-select>

                          <div
                            v-else-if="current_date > change_from_date_format"
                          >

                          <v-select
                          id="vue-selectCss"
                            v-if="monthly_plan.expired_date >= current_date"
                            v-model="item.remark"
                            :options="postponed_status"
                            :clearable="false"
                            disabled
                              data-bs-toggle="tooltip"
                              title="You can not change remark if plan is during registration period!"
                          >

                          </v-select>
                          
                        </div>
                            
                          <v-select
                          v-else
                          id="vue-selectCss"
                          v-model="item.remark"
                          :options="postponed_status"
                          :clearable="false"
                          >

                          </v-select>

                        </div>
                        <div v-else>
                          <v-select
                          id="vue-selectCss"
                          v-model="item.remark"
                          :options="postponed_status"
                          placeholder="Add Remark"
                          >
                          </v-select>
                          </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center mt-3 mb-3" v-if="!loading">
              <router-link
                :to="{
                  name: 'training-confirmation',
                }"
              >
                <button type="button" class="btn w-sm btn-secondary me-5">
                  Cancel
                </button>
              </router-link>

              <!-- <button
                v-if="
                  monthly_plan.expired_date >= current_date ||
                  monthly_plan.is_plan_confirmed == 0
                "
                style="cursor: not-allowed"
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
                data-bs-toggle="tooltip"
                :title="
                  monthly_plan.expired_date >= current_date
                    ? 'You can not change status if plan is during registration period!'
                    : 'You can not change status if plan is rejected!'
                "
              >
                Submit
              </button>
              <button
                v-else-if="monthly_plan.is_plan_confirmed == 4"
                style="cursor: not-allowed"
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
                data-bs-toggle="tooltip"
                title="You can not change status if plan is cancelled!"
              >
                Submit
              </button>
              <button
                v-else-if="
                  monthly_plan.is_plan_confirmed == 1 ||
                  monthly_plan.from_date <= current_date
                "
                style="cursor: not-allowed"
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
                data-bs-toggle="tooltip"
                :title="
                  monthly_plan.is_plan_confirmed == 1
                    ? 'You can not change status after plan is confirmed!'
                    : 'You can not change status if plan is overdue from date!'
                "
              >
                Submit
              </button>
              <button
                v-else-if="
                  filterTrainingPlan.length <= 0 && plan_confirm_status != 0
                "
                style="cursor: not-allowed"
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
                data-bs-toggle="tooltip"
                title="Data is nothing to save!"
              >
                Submit
              </button>
              <button
                v-else
                @click="updateMonthlyTrainingEnroll"
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
              >
                Submit
              </button> -->

              <!-- Normal -->

              <div
                v-if="monthly_plan.employee_type.name == 'Normal'"
                class="submit-button"
              >
                <button
                  v-if="
                    monthly_plan.is_plan_confirmed == 0 ||
                    monthly_plan.is_plan_confirmed == 1 ||
                    monthly_plan.is_plan_confirmed == 4
                  "
                  style="cursor: not-allowed"
                  type="button"
                  class="btn w-sm btn-success waves-effect waves-light"
                  data-bs-toggle="tooltip"
                  :title="
                    monthly_plan.is_plan_confirmed == 4
                      ? 'You can not change status if plan is cancelled!'
                      : 'You can not change status if plan is confirmed or rejected!'
                  "
                >
                  Submit
                </button>
                <button
                  v-else-if="monthly_plan.from_date <= current_date"
                  style="cursor: not-allowed"
                  type="button"
                  class="btn w-sm btn-success waves-effect waves-light"
                  data-bs-toggle="tooltip"
                  title="You can not change status after plan is overdue from date!"
                >
                  Submit
                </button>
                <div v-else-if="current_date > change_from_date_format">
                  <button
                    v-if="monthly_plan.expired_date >= current_date"
                    style="cursor: not-allowed"
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light"
                    data-bs-toggle="tooltip"
                    title="You can not change remark if plan is during registration period!"
                  >
                    Submit
                  </button>
                  <button
                    v-else
                    :disabled="isLoading ? true : false"
                    @click="updateMonthlyTrainingEnroll"
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light"
                  >
                    <span
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ isLoading == true ? "Loading..." : "Submit" }}
                  </button>
                </div>
                <div v-else>
                  <button
                    :disabled="isLoading ? true : false"
                    @click="updateMonthlyTrainingEnroll"
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light"
                  >
                    <span
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ isLoading == true ? "Loading..." : "Submit" }}
                  </button>
                </div>
              </div>

              <!-- Program -->

              <div v-else class="submit-button">
                <button
                  :disabled="isLoading ? true : false"
                  @click="updateMonthlyTrainingEnroll"
                  type="button"
                  class="btn w-sm btn-success waves-effect waves-light"
                >
                  <span
                    v-if="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {{ isLoading == true ? "Loading..." : "Submit" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable TrainingEnrollConfirm
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

import moment from "moment";

import $ from "jquery";
import axios from "axios";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      loading: false,
      isLoading: false,
      plan_confirm_status: "",
      confirm_status: [],
      monthly_plan: "",
      current_date: "",
      employees: this.$store.getters["odoo/getAllEmployees"],
      monthlyTrainingDetail: "",
      filterTrainingPlan: [],
      updateEmployeeData: [],
      plan_status: [
        {
          id: 0,
          name: "Rejected",
        },
        {
          id: 1,
          name: "Confirmed",
        },
        {
          id: 2,
          name: "Onprogress",
        },
        {
          id: 3,
          name: "Created",
        },
        {
          id: 4,
          name: "Cancelled",
        },
      ],
      change_from_date_format: "",
      postponed_status: [
        'This training has been postponed.',
        'You are currently in the probation period.',
        'Less of enrollment.'
      ],
      enroll_confirmed_status: [
        {
          id: 0,
          name: "Postponed",
        },
        {
          id: 1,
          name: "Confirmed",
        },
      ],
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },
  validations() {
    return {
      monthly_plan: {
        remark: { required },
      },
    };
  },
  methods: {
    capitalizeFirstLetter(str) {
      if(!str) return;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async getMonthlyPlanDetail() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v1/show-monthly-plan-detail/${this.$route.params.id}`
        )
        .then((response) => {
          this.monthly_plan = response.data.data;
          this.plan_confirm_status = this.monthly_plan.is_plan_confirmed;

          var change_from_date = new Date(this.monthly_plan.from_date);
          change_from_date.setDate(change_from_date.getDate() - 5);
          this.change_from_date_format =
            moment(change_from_date).format("YYYY-MM-DD");

          if (this.monthly_plan.employee_type.name == "Normal") {
            if (this.current_date > this.change_from_date_format) {
              this.plan_status;
            } else {
              this.plan_status.splice(0, 3);
            }
          } else {
            this.plan_status;
          }

          if (this.monthly_plan.employee_type.name == "Program") {
            this.plan_status.splice(4, 4);
          }

          this.loading = false;
        });
    },
    async getEmployees() {
      if (this.employees.length == 0) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    async getAllMonthlyTrainingDetail() {
      await this.getMonthlyPlanDetail();
      await this.getEmployees();
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/enroll-lists/${this.$route.params.id}/approved`
        )
        .then((response) => {
          this.monthlyTrainingDetail = response.data.data;

          this.filterTrainingPlan = this.monthlyTrainingDetail.map((plan) => {
            let employee = this.checklist(plan.enrolled_user_id);
            let obj = {
              emp_id: employee.emp_id,
              name: employee.name,
              position_name: employee.position_level.name,
              employee_status: employee.employee_status,
              department_name: employee.department.name,
              business_unit_name: employee.business_unit.name,
              approved_status: plan.approved_status,
              is_approved_by_trainer: plan.is_approved_by_trainer,
              remark: plan.remark,
            };
            return obj;
          });

          this.loading = false;
          this.$Progress.finish();
        });
      $("#trainingEnroll_confirm_datatable").DataTable({
        paging: false,
        searching: false,
      });
    },
    checklist(empId) {
      let resultArray;
      this.employees.forEach(function (emp) {
        if (emp.emp_id == empId) {
          resultArray = emp;
        }
      });
      return resultArray;
    },
    checkStatus(array) {
      if (array.is_approved_by_trainer == null) {
        return false;
      } else {
        return true;
      }
    },
    checkRemark(item) {
      if (
        item.remark == null ||
        item.remark == "" ||
        item.remark == undefined
      ) {
        return false;
      } else {
        return true;
      }
    },
    async updateMonthlyTrainingEnroll() {
      let updateForm = new FormData();

      updateForm.append("plan_status", this.plan_confirm_status);

      updateForm.append("plan_remark", this.monthly_plan.remark);

      updateForm.append("monthly_plan_id", this.$route.params.id);

      updateForm.append("changed_status_by", this.$store.state.auth.user.employee_id);

      var result = [];
      let checkAllStatus = [];
      let checkAllRemark = [];

      this.filterTrainingPlan.forEach((employeeData, index) => {
        var data = {
          enroll_user_id: ++index,
          employee_id: employeeData.emp_id,
          enroll_status: employeeData.is_approved_by_trainer,
          enroll_remark: employeeData.remark,
        };
        result.push(data);

        let checkEnrollStatus = this.checkStatus(employeeData);
        checkAllStatus.push(checkEnrollStatus);

        if (employeeData.is_approved_by_trainer == 0) {
          let checkEnrollRemark = this.checkRemark(employeeData);
          checkAllRemark.push(checkEnrollRemark);
        }
      });

      if (this.plan_confirm_status == 1) {
        if (checkAllStatus.includes(false)) {
          this.toast.error("Please Fill All Status!");
          return;
        }
      }

      if (this.plan_confirm_status == 0) {
        if (checkAllStatus.includes(false)) {
          this.toast.error("Please Fill All Status!");
          return;
        }
      }

      if (this.plan_confirm_status == 4) {
        if (checkAllStatus.includes(false)) {
          this.toast.error("Please Fill All Status!");
          return;
        }
      }

      if (checkAllRemark.includes(false)) {
        this.toast.error("Please add remark!");
        return;
      }

      if (this.plan_confirm_status == 0 || this.plan_confirm_status == 4) {
        const isFormCorrect = await this.v$.$validate();
        if (!isFormCorrect) {
          this.toast.error("Please add plan remark!");
          return;
        }
      }

      updateForm.append("enroll_data", JSON.stringify(result));

      this.isLoading = true;

      await axios
        .post(
          `${this.baseUrl}admin/v1/training-plan-confirmations/${this.$route.params.id}`,
          updateForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "training-confirmation" });
          this.toast.success("Successfully Training Plan Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#trainingEnroll_confirm_datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getAllMonthlyTrainingDetail();
    this.current_date = moment().format("YYYY-MM-DD");
  },
  components: {
    vSelect,
  },
};
</script>

<style>
#vue-selectCss ul {
  position: relative !important;
}

tr:last-child #lastChildCSS ul {
  position: relative !important;
}

#custom-CSS p.mb-0 {
  margin-left: 6px;
  margin-top: 6px;
}
</style>

<style scoped>
.custom-tr {
  border-style: hidden !important;
}

.status-top {
  vertical-align: inherit !important;
}

.card {
  border: 1px solid #ccc !important;
  margin-bottom: 40px !important;
}

.custom-border {
  border-right: 1px solid #ccc !important;
  height: auto;
}

.gutter-css {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}

.customWidth {
  height: 85px;
  min-width: 79px;
}

.submit-button {
  position: relative;
  cursor: pointer;
  display: inline-block;
  /* overflow: hidden; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
</style>
